import React, { useEffect, useState } from "react";

import { Link, } from "react-router-dom";
import { useLocation , useParams} from "react-router-dom";


import {  db, auth } from "../firebase";
import Footer from "../Shared/Footer";


import {Map, GoogleApiWrapper} from 'google-maps-react';
 
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import {  Modal, ModalBody } from 'react-bootstrap';

import Comprar from "../Shared/Comprar";

function Inmueble({}) {


    const { pathname } = useLocation();
    const { id } = useParams();

    const [inmueble,setInmueble] = useState({descripcion: ""})

    const [img,setImage] = useState({descripcion: ""})

    const [userdata,setUserData] = useState([])
    const [bloqueo,setBloqueo] = useState(false)

    

    useEffect(() => {
        window.scrollTo(0, 0);
        if(id !== "null"){
        getInmueble()
        } else {
          // window.location.href = "/"
        }
        if(auth.currentUser) {
          getUser()
        }
      
    }, [pathname]);




    const getUser = async () => {

      setTimeout(() => {
        if(auth.currentUser) {
          db.collection('users').doc(auth.currentUser.email).get()
          .then(doc => {
              const data = doc.data();
      
              setUserData(data)
              
              if(data.premium !== true) {
                  setBloqueo(false)
              } else {
                setBloqueo(false)
              }
          })
          .catch(err => {
            console.log('Error getting documents', err);
          });
    
        } else {
          
          window.location.href = "/register";
          
        }
      }, 2000);
     
      
   
  }
  


    const getInmueble = async () => {

        var post = {fecha: Date.now()}

        db.collection("activos-2024").doc(id).get().then((snapshot) => {
            
            post = snapshot.data()            
            setInmueble(post)

            if(post.visitas === undefined || post.visitas === null) {
                post.visitas = 1;
            } else {
                post.visitas = post.visitas + 13
            }

            if(post.propiedad === undefined || post.propiedad === null) {
              post.propiedad = "Viviendas";
          } 
          setImage(post.images[0] )

            const inmuebleRef = db.collection('activos').doc(id);
            // Atomically increment the population of the city by 50.
            const res =  inmuebleRef.update({
            visitas: post.visitas 
            });
            console.log(post.codigo)
        
          }).catch((e) => console.log(e))
    
    
    } 
  
      return (
        <div>
        {/* Page loading spinner*/}
        <main className="page-wrapper  mt-5">
        <br/>
        {/* <Modal show={bloqueo} class="modal center"  id="modalCentered"  centered>
                <ModalHeader class="modal-header"  >
                  <h5 class="modal-title">Hazte premium y accede a todos los activos</h5>
               
                </ModalHeader>
                <ModalBody class="modal-body">
                  <div className="row">
                    <div className="col-6">
                      <img  className="oferta" height="100px" src="../assets/oferta.png"></img>
                    </div>
                    <br/>
                    <div className="col-6">
                    Para poder usar <b>FotoHunters</b> adquiere la subscripción premium
                    <br/>
                    <Comprar></Comprar>
                    </div>

                  </div>
                 
                  

                </ModalBody>
            
          </Modal> */}
          <section className="container pt-0 mt-5 ">
            {/* Breadcrumb*/}
            <nav className="mb-3 pt-md-3 text-small-mobile" aria-label="breadcrumb">
              <ol className="breadcrumb text-small-mobile">
                <li className="breadcrumb-item text-small-mobile">  <Link to="/"><a>FotoHunters</a></Link> </li>
                <li className="breadcrumb-item text-small-mobile">  <Link to="/plataforma"><a>Activos</a> </Link></li>
                <li className="breadcrumb-item active text-small-mobile" aria-current="page">{id}</li>
              </ol>
            </nav>

            <p className="mb-2 pb-1 fs-lg">{inmueble?.calle}</p>
            {/* Features + Sharing*/}
            <div className="d-flex justify-content-between align-items-center">
             
            
            
            </div>

            <section class="container  mb-4 pb-3 " data-simplebar>
            <div class="row  " data-thumbnails="true">
              <div class="col-6  ">
                  <img src={img} className="fitting-image" />
              </div>
              <div className="col-md-6 ">
                <span className="badge bg-success me-2 mb-3 mt-3">Verificado</span>
        
                <div className="mb-4 pb-md-3">
                {inmueble?.calle ?  <h4><b></b>{inmueble?.calle}</h4> : null}

                {inmueble?.nHabitaciones? 
                    <ul class="d-flex mb-4 list-unstyled">
                        <li class="me-3 pe-3 border-end"><b class="me-1">{inmueble?.nHabitaciones}</b><i class="fi-bed mt-n1 lead align-middle text-muted"></i></li>
                        <li class="me-3 pe-3 border-end"><b class="me-1">{inmueble?.nBaños}</b><i class="fi-bath mt-n1 lead align-middle text-muted"></i></li>
                        <li><b>{inmueble.metros} </b>m2</li>
                      </ul>
                    : 
                    <></>
                    }


                <ul className="list-unstyled mb-0">

                    <li><b>Categoría: </b>LOCAL</li>
                    <li><b>Tipo </b>{inmueble?.operacion}</li>

                    {inmueble?.numero ?  <li><b>Número de contacto: </b>{inmueble.numero} <br/> <br/>
                    
                     <a className="btn btn-secondary" href={`tel:+34${inmueble.numero}`}> <i className="fi-phone fs-base me-2" /> Contactar número </a> </li> 
                     
                     : null}

                    
                    <div className=" col-12 col-md-12 ">

                    {inmueble?.descripcion ? <b>{inmueble?.descripcion} </b>: null} <br/>

                    {inmueble?.descripcionLong ? <b>{inmueble?.descripcionLong} </b>: null} <br/>
                    <br/>
                    Tipo de actividad: <b>{inmueble.tipoActividad}</b>
                    <br/>
                    Última actividad: <b>{inmueble.actividad}</b>

                    </div>

                  



                  </ul>
                  <br/> 
                  <p>Fecha de activo: {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(inmueble.fecha)}</p>

                  {inmueble?.precio !== 123456 && inmueble?.precio !== null  && inmueble?.precio !== undefined && inmueble?.precio !== "" ? <>
                    <h4>Precio </h4> <b>{inmueble?.precio}€ </b>
                    </>: null}

                  <br/>
                  <br/>

                  
                  <h4>Link de activo</h4>


                  <div className="col-6 card mt-3 bg-secondary p-3">
                    <a>
                    https://fotohunters.com/activo/{id}
                    </a>
                  </div>
               
            
                </div>
                
                {inmueble?.propiedad ==="Locales" ?
                    
                    <div className="card bg-dark col-12 col-md-12 text-white">
                    <h4 className="text-white">Herramientas PRO</h4>
                    Fotohunters te ofrece informes inmobiliarios hechos por profesionales del sector.  <br/>   <br/>  
                    {bloqueo? 
                    <>
                     <Link to="/pago"><button className="btn btn-primary" >Hazte premium para solicitar informe</button></Link>
                    </> 
                    :
                    <button className="btn btn-primary">Hacer informe de este local</button>
                    }
                   
                   

                    </div>
                   : null}  

    


                {/* Post meta*/}
                <div className="mb-lg-5 mb-md-4 py-4 p">
               
                  
                  <ul className="d-flex mb-4 list-unstyled fs-sm">
                    {/* <li className="me-3 pe-3 border-end">Publicada: <b>{inmueble.fecha}</b></li> */}
                    <li className="me-3 pe-3 border-end">Número id: <b>{id}</b></li>
                    <li className="me-3 pe-3 ">Visitas: <b>{inmueble?.visitas + 32}</b></li>
                  </ul>
                </div>
              </div>
            </div>
            </section>



           
          </section>
  
        </main>
        {/* Footer*/}
        <Footer></Footer>
      </div>
      );
  }


export default GoogleApiWrapper({
  apiKey: ("AIzaSyAGzVllCXc5iiC7YLNWezlRkcjg3Ocq_YU")
})(Inmueble)