import React, {useState} from "react";

import Footer from "../Shared/Footer";


import { FaCamera, FaLandmark, FaHammer, FaBullhorn } from "react-icons/fa";

import { FaSearch, FaUpload, FaTools, FaEnvelope, FaPhone } from "react-icons/fa";

const styles = {
  searchBar: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: "#ffffff"
  },
  filterDropdown: {

  },
  sectionTitle: {
    fontSize: "1.8rem",
    fontWeight: "bold",
    marginBottom: "20px"
  },
  serviceCard: {
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%"
  },
  header: {
    backgroundColor: "#F29C05",
    color: "white",
    padding: "20px",
    marginTop:120,
    textAlign: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
  },
  primaryButton: {
    backgroundColor: "#F29C05",
    borderColor: "#F29C05"
  },
  primaryIcon: {
    color: "#F29C05"
  },
  contactForm: {
    maxWidth: "600px",
    margin: "auto",
    padding: "20px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    backgroundColor: "#ffffff"
  },
  footer: {
    backgroundColor: "#F29C05",
    color: "white",
    padding: "20px",
    textAlign: "center",
    marginTop: "40px"
  }
};


const services = [
  { title: "Fotohunters", description: "Servicios profesionales de fotografía inmobiliaria.", icon: <FaCamera size={30} className="text-primary" /> },
  { title: "Venta de suelo", description: "Oportunidades de venta de terrenos para inversión y desarrollo.", icon: <FaLandmark size={30} className="text-success" />, new: true },
  { title: "Construcción", description: "Servicios de construcción y renovación de alta calidad.", icon: <FaHammer size={30} className="text-danger" />, new: true },
  { title: "Marketing", description: "Soluciones estratégicas de marketing para proyectos inmobiliarios.", icon: <FaBullhorn size={30} className="text-warning" />, new: true }
];

const PromotoresSection = () => {
  const services = [

    { title: "Publica sin coste", description: "Publica tus anuncios sin coste y llega a más compradores.", icon: <FaUpload size={30} style={styles.primaryIcon} /> },
    { title: "Marketing", description: "Soluciones de marketing para mejorar la visibilidad de tus propiedades.", icon: <FaBullhorn size={30} style={styles.primaryIcon} /> },
    { title: "Otros servicios", description: "Valoraciones, casetas de ventas, demoliciones y más.", icon: <FaTools size={30} style={styles.primaryIcon} /> }
  ];

  return (
    <div className="container mt-5">
      <h2 style={styles.sectionTitle}>Promotores</h2>
      <div className="row">
        {services.map((service, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card h-100 text-center" style={styles.serviceCard}>
              <div className="card-body">
                <div className="mb-3">{service.icon}</div>
                <h5 className="card-title">{service.title}</h5>
                <p className="card-text">{service.description}</p>
                <button className="btn" style={styles.primaryButton}>Más información</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const HunterHomesMain = () => {
  return (
    <div className=" mt-10 mb">
      <section className="background">
            <div className="row pt-0 pt-md-0 pt-lg-0">
            <div className="col-xl-12 col-lg-12 col-md-12 order-md-1 pt-xl-0 pe-lg-0 mb-0 text-md-start noMobilPadTop">
              <div className="row  text-white mt-0">
               

                <h1 className=" text-white mt-0 italic text-center"><b>Bienvenido a <span className="text-primary">HunterHomes</span></b> 
                </h1>
                <h2 className="text-white text-center">

                </h2>
              <div className="row mt-3">
                <div/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container text-center d-flex flex-column justify-content-center align-items-center mt-5 mb-5 bg-light">
        <div className="row w-100 justify-content-center">
        {services.map((service, index) => (
          <div key={index} className="col-md-3 d-flex align-items-stretch">
            <div className="card shadow-lg p-3 mb-4 bg-white rounded text-center border-0 w-100 d-flex flex-column">
              <div className="card-body d-flex flex-column justify-content-between">
                <div>
                  {service.new && <span className="badge bg-danger mb-2">Nuevo</span>}
                  <div className="mb-3">{service.icon}</div>
                  <h5 className="card-title fw-bold">{service.title}</h5>
                  <p className="card-text text-muted">{service.description}</p>
                  <br/>
                </div>
                <button className="btn btn-primary mt-auto">Saber más</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
   
      <Footer />
    </div>
  );
};




export default HunterHomesMain;
