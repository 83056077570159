import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Toaster } from 'react-hot-toast';



import Login from "./Auth/Login";
import Register from "./Auth/Register";


import Reset from "./Auth/Reset";
import AddActivo from "./Main/AddActivo";
import Add from "./Main/Add";
import Navbar from "./Shared/Navbar";
import Landing from "./Main/Landing";

import activosRetailPRO from "./Main/ActivosRetailPRO";


import Explicacion from "./Main/Explicacion";

import Dashboard from "./Main/Dashboard"

import './Theme.css';

import HazteHunter from "./Main/HazteHunter";
import Activo from "./Main/Activo";



import CRM from "./CRM/CRM";


import HuntersMapPro from "./Main/HuntersmapPro";
import CrearInforme from "./Main/CrearInforme";
import HunterHomes from "./Main/HunterHomes";



function App() {
  
  return (
    
    <div className="app">
       <Toaster></Toaster>
      <Router>
      <Navbar />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/huntermaps" component={HuntersMapPro} />

          <Route exact path="/explicacion" component={Explicacion} />
          <Route exact path="/hunters" component={HazteHunter} />

    
          <Route exact path="/activo/:id" component={Activo} />

          <Route exact path="/cuenta" component={Dashboard} />         
          <Route exact path="/register" component={Register} />


          <Route exact path="/reset" component={Reset} />

          <Route exact path="/nosotros" component={Landing} />
          <Route exact path="/crear-informe" component={CrearInforme} />

          <Route exact path="/add" component={Add} />
          <Route exact path="/add-activo/:id?" component={AddActivo} />

          <Route exact path="/login" component={Login} />


          <Route exact path="/crm" component={CRM} />


          <Route exact path="/activosRetailPRO" component={activosRetailPRO} />






        </Switch>
      </Router>
    </div>
  );
}

export default App;
