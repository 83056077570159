import React from "react";

import Footer from "../Shared/Footer";


import AlertasP from "../assets/particulares.png"
import Banner from "../assets/banner.png"
import Money from "../assets/money.png"

class HazteHunter extends React.Component {


  constructor(props) {
    super(props) 
    this.state = {
    }
  }

  componentDidMount() { }

  render() {

      return (
        <div>
        <main className="page-wrapper">
        <div className="bg-light mt-5">
        <br/>
        <br/>

     <div className="container text-center text-dark mt-5 ">
          <h1 className=" ">Gana dinero como Hunter</h1>
          <p className="contain">Fotohunters te proporciona las herramientas para ganar dinero haciendo fotos  </p>


          <div className="row  mt-5">
            <div className="row d-flex  justify-content-center mb-5">

              <div className="col-12 col-md-4">
                <img src={Banner} width={"240px"}  className="  img-fluid"></img>
                <h5>Haz foto a carteles</h5>
                <p>Ve por tu ciudad haciendo fotos a carteles</p>
                <br/>
              </div>


              <div className="col-12 col-md-4">
                <img src={AlertasP} width={"160px"}  className="icon  img-fluid"></img>
                <h5>Verificamos tus activos</h5>
                <p>Una vez que subes las fotos a <b>Fotohunters</b> pasa por el proceso de verificación</p>
                <br/>
              </div>



              <div className="col-12 col-md-4">
                <img src={Money} width={"160px"}  className="icon  img-fluid"></img>
                <h5>Gana dinero</h5>
                <p>Una vez verificado, puedes ganar <b>2.5€-300€</b> por foto dependiendo de las descargas y compras del activos</p>
                <br/>
              </div>

            </div>

          </div>
        </div>
   
        {/* Services*/}

        <section className="container mb-5 pb-2 pb-lg-4">
          <div className="row align-items-center">
            <div className="col-md-5"><img className="d-block mx-md-0 mx-auto mb-md-2 mb-4  text-md-start text-right" src="./assets/wanted.jpg" width={346} alt="Illustration" /></div>
            <div className="col-xxl-6 col-md-7 text-md-start text-center">
              <h2>Únete al equipo de hunters</h2>
              <p className="pb-3 fs-lg text-dark">!SOLICITA TU INGRESO COMO CAPTADOR Y CONSIGUE NUEVOS INGRESOS DE FORMA SENCILLA!</p><a className="btn btn-lg btn-primary" href="https://sasgm0pj550.typeform.com/to/BlQiMB1i?typeform-source=www.fotohunters.com" target={"__blank"}>
                <i className="fi-arrow-long-right me-2" />Registrate como Hunter</a>
            </div>
          </div>
        </section>
      

       

        <Footer></Footer>
      </div>
      </main>

        </div>
      );

    }
  }


export default HazteHunter;
